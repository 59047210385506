import React, { useRef } from 'react';
import {
    Button,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import icon1 from '../../../images/icon1.png';
import prev from '../../../images/prev.png';
import next from '../../../images/next.png';
import faceid9 from '../../../images/faceid9.png';
import faceid10 from '../../../images/faceid10.png';
import faceid11 from '../../../images/faceid11.png';
import faceid12 from '../../../images/faceid12.png';
import faceid13 from '../../../images/faceid13.png';
import faceid14 from '../../../images/faceid14.png';
import faceid15 from '../../../images/faceid15.png';

const ContentThree = () => {
    const swiperRef = useRef(null);
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        swiperRef.current.swiper && swiperRef.current.swiper.autoplay.stop();
    };
    
    const handleMouseLeave = () => {
        swiperRef.current.swiper && swiperRef.current.swiper.autoplay.start();
    };

    return (
        <div className='home-content-three'>
            <div className='home-content-three-bg'>
                <div className='home-content-three-cont'>
                    <div className='home-content-swiper'>
                        <div
                            className='home-content-swiper-box'
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={30}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                modules={[Pagination, Navigation, Autoplay]}
                                ref={swiperRef}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className='swiper-item-border'>
                                        <div className='swiper-item-bg'>
                                            <div className='swiper-item-text'>
                                                <div>
                                                    <div className='swiper-item-tag'>
                                                        <img src={icon1} alt="" />
                                                        <div>{'AI Tool'}</div>
                                                    </div>
                                                    <div className='swiper-item-title'>{'FaceID'}</div>
                                                    <div className='swiper-item-content'>{'FaceID Unlock the power of FaceID with just one photo! Effortlessly create any photo scene you desire. We offer a variety of scene templates to bring your vision to life.'}</div>
                                                </div>
                                                {/* <Button className='swiper-item-btn' onClick={() => navigate('/faceId_to_image')}>{'> FACEID'}</Button> */}
                                            </div>
                                            <div className='swiper-item-img'>
                                                <img src={faceid14} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='swiper-item-border'>
                                        <div className='swiper-item-bg'>
                                            <div className='swiper-item-text'>
                                                <div>
                                                    <div className='swiper-item-tag'>
                                                        <img src={icon1} alt="" />
                                                        <div>{'AI Tool'}</div>
                                                    </div>
                                                    <div className='swiper-item-title'>{'Dual FaceID'}</div>
                                                    <div className='swiper-item-content'>{'With Dual FaceID, you and your friends or family can create perfect photos together. Just one picture is all it takes!'}</div>
                                                </div>
                                                {/* <Button className='swiper-item-btn' onClick={() => navigate('/faceIdLite_to_image')}>{'> FACEID LITE'}</Button> */}
                                            </div>
                                            <div className='swiper-item-img'>
                                                <img src={faceid11} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='swiper-item-border'>
                                        <div className='swiper-item-bg'>
                                            <div className='swiper-item-text'>
                                                <div>
                                                    <div className='swiper-item-tag'>
                                                        <img src={icon1} alt="" />
                                                        <div>{'AI Tool'}</div>
                                                    </div>
                                                    <div className='swiper-item-title'>{'StylerID'}</div>
                                                    <div className='swiper-item-content'>{"Stylize with TensorAI's 16 built-in styles, including Clay, Felt, Sketch, Pixar, and American Comics. We're constantly updating with new styles to unlock endless creative possibilities!"}</div>
                                                </div>
                                                {/* <Button className='swiper-item-btn' onClick={() => navigate('/styleId_to_image')}>{'> STYLEID'}</Button> */}
                                            </div>
                                            <div className='swiper-item-img'>
                                                <img src={faceid12} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <div className='swiper-item-border'>
                                        <div className='swiper-item-bg'>
                                            <div className='swiper-item-text'>
                                                <div>
                                                    <div className='swiper-item-tag'>
                                                        <img src={icon1} alt="" />
                                                        <div>{'AI Tool'}</div>
                                                    </div>
                                                    <div className='swiper-item-title'>{'FaceID'}</div>
                                                    <div className='swiper-item-content'>{'FaceID Unlock the power of FaceID with just one photo! Effortlessly create any photo scene you desire. We offer a variety of scene templates to bring your vision to life.'}</div>
                                                </div>
                                                <Button className='swiper-item-btn' onClick={() => navigate('/styleId_to_image')}>{'> STYLEID'}</Button>
                                            </div>
                                            <div className='swiper-item-img'>
                                                <img src={faceid11} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                                <SwiperSlide>
                                    <div className='swiper-item-border'>
                                        <div className='swiper-item-bg'>
                                            <div className='swiper-item-text'>
                                                <div>
                                                    <div className='swiper-item-tag'>
                                                        <img src={icon1} alt="" />
                                                        <div>{'AI Tool'}</div>
                                                    </div>
                                                    <div className='swiper-item-title'>{'StylerID'}</div>
                                                    <div className='swiper-item-content'>{"Stylize with TensorAI's 16 built-in styles, including Clay, Felt, Sketch, Pixar, and American Comics. We're constantly updating with new styles to unlock endless creative possibilities!"}</div>
                                                </div>
                                                {/* <Button className='swiper-item-btn' onClick={() => navigate('/styleId_to_image')}>{'> STYLEID'}</Button> */}
                                            </div>
                                            <div className='swiper-item-img'>
                                                <img src={faceid15} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <img src={prev} className="swiper-button-prev" alt="" />
                            <img src={next} className="swiper-button-next" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentThree;