import React, { useRef, useEffect } from 'react';
import './index.css';
import centerpiece_video from '../../../images/centerpiece_video.mp4'
import { useTranslation } from 'react-i18next';
import homebackground from '../../../images/homebackground.png'

const ContentOne = () => {
    const { t } = useTranslation();

    return (
        <div
            className='content-one'
            style={{backgroundImage: `url(${homebackground})`}}
        >
            {/* <video
                className='content-video'
                src={centerpiece_video}
                autoPlay //自动播放
                loop    //循环播放
                controls={false} //控件
                muted //静音
            ></video> */}
            <div className='content-title'>
                <div className='text'>
                    {t('home.title')}
                </div>
            </div>
        </div>
    );
};

export default ContentOne;