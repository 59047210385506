import React from 'react';
import ContentOne from './contentOne';
import ContentTwo from './contentTwo';
import ContentThree from './contentThree';

const Home = () => {
    return (
        <div>
            <ContentOne/>
            <ContentTwo/>
            <ContentThree/>
        </div>
    );
};

export default Home;