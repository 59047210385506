import React from 'react';
import './index.css';
import styleid1 from '../../../images/styleid1.png';
import styleid2 from '../../../images/styleid2.png';
import styleid3 from '../../../images/styleid3.png';
import styleid4 from '../../../images/styleid4.png';
import styleid5 from '../../../images/styleid5.png';
import faceid1 from '../../../images/faceid1.png';
import faceid2 from '../../../images/faceid2.png';
import faceid3 from '../../../images/faceid3.png';
import faceid4 from '../../../images/faceid4.png';
import faceid5 from '../../../images/faceid5.png';
import faceid6 from '../../../images/faceid6.png';
import faceid7 from '../../../images/faceid7.png';
import faceid8 from '../../../images/faceid8.png';
import { useNavigate } from 'react-router-dom';
import { Flex, Image } from 'antd';

const ContentTwo = () => {
    const navigate = useNavigate();

    const handleButtonClick = (props) => {
        return;
        // switch (props) {
        //     case 1:
        //         navigate('/face_change');
        //         break;
        //     case 2:
        //         navigate('/text_style_image');
        //         break;
        //     case 3:
        //         navigate('/text_to_image');
        //         break;
        //     case 4:
        //         navigate('/portrait_master');
        //         break;
        //     case 5:
        //         navigate('/superfraction_repair');
        //         break;
        //     case 6:
        //         navigate('/image_to_image');
        //         break;
        //     case 7:
        //         navigate('/faceId_to_image');
        //         break;
        //     case 8:
        //         navigate('/faceIdLite_to_image');
        //         break;
        //     case 9:
        //         navigate('/styleId_to_image');
        //         break;
        //     default:
        //         navigate('/text_style_image');
        //         break;
        // }
    };

    return (
        <div className='contentTwo'>
            <div className='contentTwo-title'>{'FaceID'}</div>
            <Flex wrap gap="small">
                {[faceid1, faceid2, faceid3, faceid4].map((src, index) => (
                    <div key={index} className='card'>
                        <Image src={src} alt={`FaceID ${index + 1}`} />
                        {/* <div className='card-content'>
                            <div></div>
                            <div>
                                <div className='card-title'>FACEID</div>
                                <div className='card-text'></div>
                            </div>
                        </div> */}
                    </div>
                ))}
            </Flex>
            <Flex wrap gap="small">
                {[faceid5, faceid6, faceid7, faceid8].map((src, index) => (
                    <div key={index} className='card'>
                        <Image src={src} alt={`FaceID ${index + 5}`} />
                        {/* <div className='card-content'>
                            <div></div>
                            <div>
                                <div className='card-title'>FACEID</div>
                                <div className='card-text'></div>
                            </div>
                        </div> */}
                    </div>
                ))}
            </Flex>
            <div className='contentTwo-title'>{'StylerID'}</div>
            <Flex wrap gap="small">
                {[{file:styleid1, name: 'CLAY'}, {file:styleid4, name: 'PIXEL'}, {file:styleid3, name: 'GENSHIN IMPACT'}, {file:styleid5, name: 'GTA5'}].map((item, index) => (
                    <div key={index} className='card' onClick={() => handleButtonClick(9)}>
                        <img src={item.file} alt={`StyleID ${index + 1}`} />
                        <div className='card-content'>
                            <div></div>
                            <div>
                                <div className='card-title'>{item.name}</div>
                                <div className='card-text'></div>
                            </div>
                        </div>
                    </div>
                ))}
            </Flex>
        </div>
    );
};

export default ContentTwo;