import axios from 'axios';
//token  z9Bc4dEf1Kh2IjKl   ak5giHqec7Dtxgph
//34.125.49.149    广州团队在使用
//34.125.135.253
//35.230.94.81 1111111111111111111111111文声图
//34.125.91.207  L4
//10.168.2.225:8010  金哥电脑
//10.168.2.225:8002  金哥电脑
//34.168.156.23
//34.125.100.62   生产地址
//test-server.internal.tensorbee.com
//34.168.10.165
//184.72.54.32
//10.168.2.151
//54.184.193.212
//34.168.10.165 

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';



export const AdminGetServerData = ({data, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${ip}/v1/faceswap/specifyimage?tab=${data}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve();
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const AdminStartServer = ({data, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceswap/specifyimage`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve();
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const FaceSwipeAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceswap/specifyimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceswap/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const TextStyleImageAPI = ({param, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/images/imagegenerate`, param, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const TextStyleImageGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/images/getimagestyle`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};

export const SuperfractionRepairAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/images/imageupscaler`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(data);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const ImageToImageAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/stylersync/stylersync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/stylersync/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const ImageToImageGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/images/getimagestyle`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceid/faceid`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceid/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/faceid/getimagestyle?type=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdLiteAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceidlite/faceidlite`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidlite/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const StyleIdAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        // axios.post(`http://${ip}/v1/stylerid/stylerid`, formData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'Authorization': 'Bearer ' + token
        //     },
        // }).then(({data}) => {
        //     if (data.code === 1000) {
        //         function makeRequest() {
        //             axios.post(`http://${ip}/v1/stylerid/infobytaskid`, {
        //                 taskid: data.data.taskid
        //             }, {
        //                 headers: {
        //                     'Content-Type': 'application/json',
        //                     'Authorization': 'Bearer ' + token
        //                 }
        //             }).then(res => {
        //                 if (res.data.data.url) {
        //                     clearInterval(intervalId);
        //                     resolve(res.data);
        //                 }
        //             }).catch(error => {
        //                 clearInterval(intervalId);
        //                 reject(error);
        //             });
        //         }
        //         const intervalId = setInterval(makeRequest, 1000);
        //     } else {
        //         reject(new Error());
        //     };
        // }).catch((error) => {
        //     reject(error);
        // })
        axios.post(`http://${ip}/v1/faceid/faceid`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceid/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const StyleIdGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/stylerid/getimagestyle?type=9`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const PoseFaceAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceidpose/faceidpose`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidpose/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const DoublePoseFaceAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/sceneimagegen/scenariosimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidpose/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const RemoveBgAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceswap/specifyimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceswap/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const PhotoSyncAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/photosync/photosync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/photosync/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};