import React, { useState, useEffect, useRef } from "react";
import './index.css';
import {
    Button,
    Input,
    Image,
    Spin,
    Form,
    Select,
    message,
    Tooltip,
    Radio
} from 'antd';
import {
    QuestionCircleOutlined,
    DownloadOutlined,
    UpOutlined,
    DownOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PubInput from '../../components/pubInput';
import {
    TextStyleImageAPI,
    TextStyleImageGetStyleNode
} from '../../api/request.js'
const { TextArea } = Input;

const TextStyleImage = (props) => {
    const { config } = props;
    const [getImageUrl, setGetImageUrl] = useState('');
    const [seed, setSeed] = useState('请输入种子');
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [showInputs, setShowInputs] = useState(true);
    const [options, setOptions] = useState([]);

    const formRef = useRef(null);
    const navigate = useNavigate();

    const sendPrompt = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    }
    
    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const stochasticSeed = Date.now();
            let param;
            if (values.seed) {
                param = {
                    prompt: values.text_area,
                    seed: Number(values.seed),
                    img_width: config?.debug ? 512 : Number(values.ratio.split(" ")[0]),
                    img_height: config?.debug ? 512 : Number(values.ratio.split(" ")[1]),
                    style: values.style,
                    num: 1
                }
            } else {
                setSeed(stochasticSeed);
                param = {
                    prompt: values.text_area,
                    seed: stochasticSeed,
                    img_width: config?.debug ? 512 : Number(values.ratio.split(" ")[0]),
                    img_height: config?.debug ? 512 : Number(values.ratio.split(" ")[1]),
                    style: values.style,
                    num: 1
                }
            }
            const res = await TextStyleImageAPI({param: param, ip: config.ip, token: config.token});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，请重试');
            };
        } catch (error) {
            message.error('生成失败，请重试');
        };
        setDisabledSubmit(false);
    };

    const getStyleNode = async () => {
        try {
            const response = await TextStyleImageGetStyleNode(config);
            let opData = [];
            if (response.code === 1000) {
                for (let i = 0; i < response.data.length; i++) {
                    opData.push({
                        label: response.data[i],
                        value: response.data[i]
                    });
                }
                setOptions(opData);
            }
        } catch (error) { };
    };

    const initialValues = {
        style: 'film',
        ratio: '1024 1024'
    };

    const handleDownload = () => {
        const imageUrl = getImageUrl;
    
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getStyleNode();
    }, []);

    return (
        <div className="text-style-image">
            {/* <div className="text-style-image-header">
                <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
                <div>Playground</div>
            </div> */}
            <div className="text-style-image-box">
                <div className="prompt">
                    <Form
                        name="validate_other"
                        onFinish={handleFormSubmit}
                        className='form'
                        ref={formRef}
                        initialValues={initialValues}
                    >
                        <div className="title">
                            <span>Prompt</span>
                            <Tooltip placement="top" title={'生成图片的关键提示词'} arrow={true}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <Form.Item
                            name="text_area"
                            rules={[{ required: true, message: 'Please input Prompt!' }]}
                        >
                            <TextArea
                                rows={4}
                                placeholder="Please input Prompt!"
                                className="text-area"
                                allowClear
                                disabled={disabledSubmit}
                            />
                        </Form.Item>
                        <div className="title up-down-title" onClick={() => setShowInputs(!showInputs)}>
                            <span>Advanced</span>
                            {
                                showInputs ?
                                <UpOutlined className="up-icon"/> : <DownOutlined className="up-icon"/>
                            }
                        </div>
                        <div style={{ display: showInputs ? 'block' : "none" }}>
                            {
                                options.length > 0 ?
                                    <>
                                        <div className="title">
                                            <span>Style</span>
                                            <Tooltip placement="top" title={'生成的图片风格'} arrow={true}>
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name="style"
                                            noStyle
                                        >
                                            <Select
                                                allowClear
                                                placeholder="请选择风格"
                                                className="style"
                                                options={options}
                                                disabled={disabledSubmit}
                                            />
                                        </Form.Item>
                                    </> : <></>
                            }
                            <PubInput
                                title='Seed'
                                name='seed'
                                typr='number'
                                placeholder={seed}
                                disabled={disabledSubmit}
                                iconTitle='种子可以是随机也可以是固定，默认不输入就是随机'
                            />
                            <div className="title">
                                <span>Ratio</span>
                                <Tooltip placement="top" title={'生成的图片分辨率'} arrow={true}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                            <Form.Item
                                name="ratio"
                                noStyle
                            >
                                <Radio.Group className="ratio" disabled={disabledSubmit}>
                                    <Radio.Button value="512 512">512 * 512</Radio.Button>
                                    <Radio.Button value="512 768">512 * 768</Radio.Button>
                                    <Radio.Button value="768 512">768 * 512</Radio.Button>
                                    <Radio.Button value="1024 1024">1024 * 1024</Radio.Button>
                                    <Radio.Button value="768 1280">768 * 1280</Radio.Button>
                                    <Radio.Button value="1280 768">1280 * 768</Radio.Button>
                                    <Radio.Button value="1664 704">1664 * 704</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Form>
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        className="but"
                        onClick={sendPrompt}
                        disabled={disabledSubmit}
                    >
                        Generate
                    </Button>
                </div>
                <div className="output-image">
                    <div className="output-image-bfc">
                        { getImageUrl ? <Image src={getImageUrl} className="img"/> : <></> }
                        {
                            disabledSubmit ?
                            <div className="loading-box">
                                <Spin size="large" className="loading" />
                                <div className="text" >正在生成中，请稍等...</div>
                            </div> : <></>
                        }
                    </div>
                    {
                        getImageUrl ?
                            <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                                <Button
                                    onClick={handleDownload}
                                    className="but"
                                    icon={<DownloadOutlined />}
                                ></Button>
                            </Tooltip> : <></>
                    }
                </div>
            </div>
        </div>
    )
};
export default TextStyleImage;