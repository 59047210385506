import React, { useState } from "react";
import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    UserOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import footerlogo from '../../images/footerlogo.png';

const MyFooter = () => {
    const location = useLocation();
    if (location.pathname === '/toc' || location.pathname === '/privacy') {
        return null;
    };

    return <div className="footer-heart">
        <img className="logo" src={footerlogo} alt="" />
        <div className="bottom">
            <div>TensorAI © 2024</div>
            <div>All rights reserved.</div>
        </div>
    </div>
}

export default MyFooter;