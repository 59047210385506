import React from "react";
import './index.css';
import {
    Input,
    Form,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';
const { TextArea } = Input;

const TextAreaInput = (props) => {
    const {
        title,
        name,
        iconTitle = '',
        disabled = false,
        placeholder = '',
        height = '120px'
    } = props;

    return (<div className="text-area-input">
        <div className="text-area-title">
            <span>{title}</span>
            <Tooltip placement="top" title={iconTitle} arrow={true}>
                <QuestionCircleOutlined />
            </Tooltip>
        </div>
        <Form.Item
            name={name}
            noStyle
        >
            <TextArea
                rows={4}
                placeholder={placeholder}
                className="text-area-item"
                disabled={disabled}
                style={{ height: height, minHeight: '50px' }}
            />
        </Form.Item>
    </div>
    )
};
export default TextAreaInput;