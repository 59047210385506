import i18n from 'i18next';
import en from './en';
import zh from './zh';
import { initReactI18next } from 'react-i18next';
const userLanguage = navigator.language;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them)
        resources: {
            en: {
                translation: en
            },
            zh: {
                translation: zh
            }
        },
        lng: userLanguage === 'zh-CN' ? 'zh' : 'en',
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;